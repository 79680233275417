import React from 'react';
import useStyles from './Successfull.styles';
import { Button, Container, Grid, Hidden, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NavBarV2 from '../../Header/NavBarV2';
import { navigate } from 'gatsby';
import CbTable from './CbTable';
const Successfull = ({ uuid }: { uuid?: any }) => {
  const classes = useStyles();
  window.localStorage.removeItem('bearer');
  const handleClick = () => {
    if (typeof window !== `undefined`) {
      window.localStorage.getItem('uuid');
      navigate(`${process.env.GATSBY_URL_NEW_FRONT}/login`, { state: { uuid } });
    }
  };
  return (
    <section id="" className={classes.root}>
      <NavBarV2 />
      <Container className={classes.container} maxWidth={false}>
        <Grid container>
          <Hidden smDown>
            <Grid item md={6} className={classes.feather} />
          </Hidden>

          <Grid className={classes.rightCol} item xs={12} md={5} lg={4}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <h1>
                  Registro completo
                  <br />
                </h1>
              </Grid>
              <Grid item xs={12}>
                <h4>¡Gracias por registrarte en CurrencyBird!</h4>
                <p>
                  En estos momentos  nuestro equipo de cumplimiento está validando y verificando tu
                  información.
                </p>
                <p>
                  Una vez validados tus datos, recibirás un correo desde{' '}
                  <b>registro@currencybird.cl</b> confirmando que ya puedes enviar y recibir dinero
                  con CurrencyBird.{' '}
                </p>
                <p>Si te registraste en horario hábil, esto suele tardar entre 5 a 60 minutos.</p>
                <p>
                  Por mientras, te invitamos a que ingreses a tu perfil para que empieces a crear
                  tus contactos de transferencias internacionales.
                </p>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                  className={classes.button}
                  style={{ textTransform: 'inherit' }}
                  endIcon={<CheckCircleIcon />}
                >
                  Ingresar a mi cuenta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default Successfull;
